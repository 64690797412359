º<script setup lang="ts">
  import useCpBem from '~/composables/useCpBem';
  import CpIconStandard from '~/components/cp/CpIcon/CpIconStandard/CpIconStandard.vue';
  import CpText from '~/components/cp/CpText/CpText.vue';

  const props = withDefaults(defineProps<{
    kind: 'standard'|'reviews',
    /** background color **/
      variant?: 'primary'|'mkt'|'neutralStandard'|'neutralExtraLight'|'success'|'warning'|'white'|'secondary',
    /** label color **/
    labelKind?: 'white'|'primary'|'neutral',
    label?: string,
    icon?: string,
    quantity?: string,
    /** border color **/
    border?: 'borderPrimary'|'borderWhite'|'borderNeutral',
    alignEnd?: boolean
    withoutBorder?: boolean
  }>(), {
    kind: 'standard',
    variant: 'primary',
    alignEnd: false,
    withoutBorder: false,
  });

  const {bm, e} = useCpBem('cp-pill-pdp');
</script>

<template>
    <div :class="bm( { [variant]: true, [border]: !withoutBorder, 'align-end': alignEnd })" data-testid="pill-pdp">
        <CpText
            v-if="label && kind === 'standard'"
            :class="e`text`"
            variant="caption-bold"
            :type="labelKind"
        >
            {{ label }}
        </CpText>
        <CpText
            v-if="kind === 'reviews'"
            :class="e`text`"
            variant="caption-bold"
            :type="labelKind"
        >
            {{ quantity }} {{ label ?? '' }}
        </CpText>
        <CpIconStandard
            v-if="icon"
            :icon="icon"
            type="white"
            kind="small"
        />
    </div>
</template>

<style scoped lang="scss">

  .cp-pill-pdp {
      width: fit-content;
      display: flex;
      gap: 4px;
      justify-content: center;
      align-items: center;
      padding: 4px 8px;
      border-radius: 16px;
      height: 20px;
      &--primary {
        background: var(--color-primary);
      }
      &--secondary {
        background: var(--color-secondary);
      }
      &--mkt {
        background: var(--color-mkt);
      }
      &--success {
        background: var(--color-success);
      }
      &--warning {
        background: var(--color-warning);
      }
      &--neutral-standard {
        background: var(--color-neutral);
      }
      &--neutral-extra-light {
        background: var(--color-neutral-extra-light);
      }
      &--border-primary {
        border: 1px solid var(--color-primary);
      }
      &--border-neutral {
        border: 1px solid var(--color-neutral);
      }
      &--border-white {
        border: 1px solid var(--color-white);
      }
      &--align-end {
        align-items: flex-end;
      }
      // &--white {
      //   background: var(--color-white);
      // }
      &__magic-icon {
        width: 19px;
      }
  }
</style>
